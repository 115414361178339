<template>
  <form @submit.prevent="login">
    <div class="p-field">
      <div class="p-text-center">
        <span class="p-input-icon-left">
          <i class="pi pi-user"/>
          <InputText ref="emailInput" id="email" type="email" :class="errors.email ? 'p-invalid' : ''"
                     v-model="form.email" placeholder="Email" required autofocus/>

          <div v-if="errors.email">
            <small id="email-error" class="p-error">{{ this.errors.email }}</small>
          </div>

        </span>
      </div>
    </div>

    <div class="p-field">
      <div class="p-text-center">
        <span class="p-input-icon-left p-mt-1">
          <i class="pi pi-key"/>
          <InputText id="password" type="password" v-model="form.password" placeholder="Password" required/>
        </span>
      </div>
    </div>

    <div class="p-mt-3 p-text-center">
      <Button type="submit" label="Submit"/>
    </div>
  </form>

  <Toast/>

</template>

<script>
import {UserTypes} from "src/constants";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errors: {
        email: null,
        password: null,
      },
      loading: true,
    }
  },
  methods: {
    async login() {
      this.loading = true;

      try {
        /** @type {Response} */
        const response = await this.$repository.auth.login(this.form);

        const adminTypes = [UserTypes.admin, UserTypes.super_admin];

        if (!adminTypes.includes(response.data.user.type)) {
          this.$toast.add({
            severity: 'error',
            summary: 'Usuario no valido',
            detail: 'Solo usuarios tipo ADMIN, pueden ingresar'
          });

          this.cleanForm();

          return;
        }

        localStorage.setItem('access_token', response.data.access_token)
        localStorage.setItem('user', JSON.stringify(response.data.user))

        this.$router.push({
          path: '/'
        });

      } catch (err) {

        this.$toast.add({
          severity: 'error',
          summary: err.response.data.message
        });

      } finally {
        this.loading = false;
      }
    },
    cleanForm() {
      this.email = null;
      this.password = null;
      this.$refs.emailInput.focus();
    }
  }
}
</script>

<style scoped lang="scss">
</style>