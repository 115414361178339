<template>
  <div class="p-text-center p-mt-5">
    <h1>Iniciar Sesión</h1>
  </div>

  <div class="p-grid p-mt-4 p-jc-center">
    <div class="p-fluid p-col p-md-3 p-lg-4 p-xl-6">
      <Card class="content form-login">
        <template #content>
          <LoginForm/>
        </template>
        <template #footer>
          <Button type="button" class="p-button-link" @click="goToPasswordReset">Reset Password</Button>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import LoginForm from "src/components/login/LoginForm";

export default {
  components: {
    LoginForm
  },
  methods: {
    goToPasswordReset() {
      this.$router.push('password-reset')
    }
  }
}
</script>

<style scoped>

</style>